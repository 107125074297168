<script lang="ts">
  import { AceEditor } from "svelte-ace";
  import "brace/mode/json";
  import "brace/theme/monokai";

  import {
    decisionRequest as decisionRequestStore,
    isLoading,
    decision as decisionStore,
    currentDecision,
    isError,
    errorMessage,
    apiKey as apiKeyStore,
    selectedResultPanel,
    desiredAdIds as desiredAdIdsStore,
  } from './stores';

  import InputBar from "./InputBar.svelte";

  let decisionRequest: string, apiKey: string, desiredAdIds: string

  $: {
    decisionRequest = $decisionRequestStore;
    apiKey = $apiKeyStore;
    desiredAdIds = $desiredAdIdsStore;
  };

  let getExplanation = async () => {
    if ((apiKey || '') === '') {
      isError.set(true);
      errorMessage.set('Your Kevel API Key is required!');

      setTimeout(function(){
          isError.set(false);
        }, 4000);
      return;
    }

    isLoading.set(true);

    let r = JSON.parse(decisionRequest);
    let networkId = r.placements[0].networkId;

    let args = {
      type: 'decision',
      networkId: networkId,
      apiKey,
      data: r,
      desiredAdIds: undefined,
    };

    if (!!desiredAdIds) {
      args.desiredAdIds = desiredAdIds.split(',').map((id) => parseInt(id.trim(), 10));
    }

    let response: any;

    try {
      response = await fetch(process.env.API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(args),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
        isLoading.set(false);
        isError.set(true);
        errorMessage.set('Error! NetworkId, SiteId, and AdTypes are Required. Please check that they are accurate for formatted correctly.');

        setTimeout(function(){
          isError.set(false);
        }, 4000);
    }

    isLoading.set(false);
    let decision = await response.json();

    if(!decision.explain) {
      isError.set(true);
      errorMessage.set('Error! Your request returned empty. Please check to make sure your API Key, NetworkId, and SiteId are correct.');

      setTimeout(function(){
          isError.set(false);
      }, 4000);
      return;
    }

    if (JSON.stringify($currentDecision) != JSON.stringify(decision)) {
      $selectedResultPanel = "results";
    }

    decisionStore.set(decision);
  }
</script>

<div class="min-w-full px-8">
  <InputBar />

  <div class="min-w-full shadow-xl">
    <AceEditor
      on:input={(e) => {
        decisionRequestStore.set(e.detail)
      }}
      lang="json"
      theme="monokai"
      width="100%"
      height="750px"
      value={decisionRequest}
      options={{fontSize: '18px'}} />
  </div>
</div>

<button class="bg-orange-300 hover:bg-orange-500 text-white font-bold py-2 px-4 border border-orange-500 rounded mt-10"
        on:click={getExplanation}>
  Get my Explanation, please!
</button>
