<script lang="ts">
  import Icon from "fa-svelte";
  import { slide } from 'svelte/transition';
  import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
  import { apiKey as apiKeyStore } from "./stores";
  import { run as makeManagementRequest } from './services/mgmt';
  import Targeting from "./Targeting.svelte";
  import Pacing from "./Pacing.svelte";

  export let value: any;
  export let networkId: any;

  let apiKey: string;
  let isExpanded: boolean = false;
  let selectedDetailsPanel: string = 'targeting';

  let campaignPromise: Promise<any> = Promise.resolve({ name: "..." });
  let adPromise: Promise<any> = Promise.resolve({
    impressions: "...",
    goalType: "...",
    freqCap: "...",
    freqCapDuration: "...",
    freqCapType: "...",
    goal: "..."
  });
  let flightPromise: Promise<any> = Promise.resolve({
    name: "...",
    impressions: "...",
    isFreqCap: "...",
    goalType: "...",
    freqCap: "...",
    freqCapDuration: "...",
    freqCapType: "...",
    capType: "...",
    dailyCapAmount: "...",
    lifetimeCapAmount: "...",
    dontAffectParentFreqCap: "...",
  });
  let advertiserPromise: Promise<any> = Promise.resolve({ title: "..." });
  let channelPromise: Promise<any> = Promise.resolve({ title: "..." });
  let priorityPromise: Promise<any> = Promise.resolve({ name: "..." });

  let fetchAdAndRelationships = async (id) => {
    let ad = await makeManagementRequest('ad', 'get', { id: value.ad }, apiKey);
    if (ad.creative.adTypeId) {
      let adTypeList = await makeManagementRequest('adType', 'listForChannel', { channelId: value.channel }, apiKey);
      adTypeList.items.forEach(type => {
        if (type.id === ad.creative.adTypeId) {
          ad.adTypeInfo = type;
        }
      });
    }
    return ad;
  }

  $: {
    apiKey = $apiKeyStore;
    campaignPromise = makeManagementRequest("campaign", "get", {
      id: value.campaign,
    }, apiKey);
    flightPromise = makeManagementRequest("flight", "get", {
      id: value.flight,
    }, apiKey);
    advertiserPromise = makeManagementRequest("advertiser", "get", {
      id: value.advertiser,
    }, apiKey);
    channelPromise = makeManagementRequest("channel", "get", {
      id: value.channel,
    }, apiKey);
    priorityPromise = makeManagementRequest("priority", "get", {
      id: value.priority,
    }, apiKey);
    adPromise = fetchAdAndRelationships(value.ad);
  }
</script>

<div class="pb-10 min-w-full">
  <div class="w-full lg:max-w-full lg:flex">
    <div class="border-r border-b bordr-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal flex-grow">
      <div class="mb-5">
        <p class="text-sm text-gray-700 flex items-center mb-1">
          Campaign
          {#await campaignPromise}...{:then c}
            {c.name}
          {/await}
          (Id:
          <a
            href={`https://app.kevel.co/#!/${networkId}/campaign/${value.campaign}/flights`}
            target="_blank">
            {value.campaign}
            <Icon class="-mt-1" icon={faExternalLinkAlt} />
          </a>
          )
        </p>
        <div class="text-gray-900 font-bold text-2xl mb-4">
          Ad: {value.ad}
        </div>
        <div class="text-gray-700 font-bold text-xl mb-4">
          Info: {value.info}
        </div>
        <div class="mb-4 text-gray-700 text-l ">
          {#await adPromise}...{:then a}
            <span class="font-bold">Creative: </span>
            {a.creative.title} (ID: {a.creative.id})
          {/await}
        </div>
        <div class="text-gray-700 text-l mb-4">
          <span class="font-bold">Phase: </span>{value.phase}
        </div>
        <div class="mb-4 text-l text-gray-700">
          <span class="font-bold">AdType: </span>
          {#await adPromise}...{:then a}
            {#if a.adTypeInfo.name}
              {a.adTypeInfo.name}
            {:else}
              {a.adTypeInfo.height}x{a.adTypeInfo.width}
            {/if}
            <span class="text-gray-600 text-sm whitespace-nowrap">(ID: {a.creative.adTypeId})</span>
          {/await}
        </div>

        <div class="text-gray-700 text-base flex">
          <div>
            <div class="font-bold">Flight</div>
            <div>{#await flightPromise}...{:then f}{f.name}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">
              (ID:
              <a
                href={`https://app.kevel.co/#!/${networkId}/flight/${value.flight}/edit`}
                target="_blank">
                {value.flight}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-10">
            <div class="font-bold">Advertiser</div>
            <div>{#await advertiserPromise}...{:then a}{a.title}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">
              (ID:
              <a
                href={`https://app.kevel.co/#!/${networkId}/advertiser/${value.advertiser}/campaigns`}
                target="_blank">
                {value.advertiser}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-10">
            <div class="font-bold">Channel</div>
            <div>{#await channelPromise}...{:then c}{c.title}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">
              (ID:
              <a
                href={`https://app.kevel.co/#!/${networkId}/channels/`}
                target="_blank"
              >
                {value.channel}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-10">
            <div class="font-bold">Priority</div>
            <div>{#await priorityPromise}...{:then p}{p.name}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">
              (ID:
              <a href={`https://app.kevel.co/#!/${networkId}/channel/${value.channel}/priorities/`}
                target="_blank">
                {value.priority}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-10">
            <div class="font-bold">ECPM</div>
            <div class="text-gray-600 text-sm">{value.ecpm}</div>
          </div>
          <div class="ml-10">
            {#if value.useFlightWeight}
              <div class="font-bold">Flight Weight</div>
              <div class="text-gray-600 text-sm">{value.flightWeight}</div>
            {:else}
              <div class="font-bold">Ad Weight</div>
              <div class="text-gray-600 text-sm">{value.weight}</div>
            {/if}
          </div>
        </div>
        <div class="mt-5">
          <button on:click={() => isExpanded = !isExpanded} class="text-gray-600 text-sm">
            {isExpanded ? "[-] Hide Details" : "[+] View Details"}
          </button>
        </div>
        {#if isExpanded}
          <div class="flex-grow bg-white" transition:slide|local>
            <nav class="mb-8   text-base transform opactiy-100 text-gray-800 font-bold">
              <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'targeting' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
                <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="targeting" />
                Targeting
              </label>
              <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'pacing' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
                <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="pacing" />
                Pacing
              </label>
            </nav>
            {#if selectedDetailsPanel === 'targeting'}
              <Targeting
                value={value} />
            {:else if selectedDetailsPanel === 'pacing'}
              <Pacing
                value={value} />
            {/if}
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
