<script lang="ts">
	import Icon from 'fa-svelte';
	import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
	import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
	import { faHandPointDown } from '@fortawesome/free-solid-svg-icons/faHandPointDown';
	import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote';

	import { selectedResultPanel, decision as decisionStore } from './stores';

	let backHandler = () => {
		decisionStore.set(undefined);
	};
</script>

<nav class="mb-6 text-base transform opacity-100 text-gray-800 font-bold min-w-full">
  <span class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 border-opacity-0 -mr-1" on:click={backHandler}>
  	<Icon class="mr-2 text-xl -mt-1" icon={faArrowLeft} />
  	<span class="hidden lg:inline">Make a New Request</span>
  </span>
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {$selectedResultPanel === 'results' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
  	<input class="hidden" type="radio" bind:group={$selectedResultPanel} value="results">
  	<Icon class="mr-2 text-xl -mt-1" icon={faListUl} />
  	<span class="hidden lg:inline">Results</span>
  </label>
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {$selectedResultPanel === 'candidates' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
  	<input class="hidden" type="radio" bind:group={$selectedResultPanel} value="candidates">
  	<Icon class="mr-2 text-xl -mt-1" icon={faHandPointDown} />
  	<span class="hidden lg:inline">Candidates</span>
  </label>
	<label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {$selectedResultPanel === 'desiredAds' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
  	<input class="hidden" type="radio" bind:group={$selectedResultPanel} value="desiredAds">
  	<Icon class="mr-2 text-xl -mt-1" icon={faHandPointDown} />
  	<span class="hidden lg:inline">Desired Ads</span>
  </label>
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {$selectedResultPanel === 'user' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
	<input class="hidden" type="radio" bind:group={$selectedResultPanel} value="user">
	<Icon class="mr-2 text-xl -mt-1" icon={faHandPointDown} />
	<span class="hidden lg:inline">UserDB</span>
</label>
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {$selectedResultPanel === 'raw' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
  	<input class="hidden" type="radio" bind:group={$selectedResultPanel} value="raw">
  	<Icon class="mr-2 text-xl -mt-1" icon={faStickyNote} />
  	<span class="hidden lg:inline">Decision Response JSON</span>
  </label>
</nav>