<script lang="ts">
    import { apiKey  } from './stores';
    import Icon from "fa-svelte";
    import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
    import { slide } from 'svelte/transition';
    import { AceEditor } from 'svelte-ace';
    import { run as makeManagementRequest } from './services/mgmt';

    export let userKey: any;
    export let networkId: any;
    
    let selectedDetailsPanel: string = 'details';

    export const read = async (networkId: any, userKey: any) => {
        let args = {
            type: 'userdb',
            networkId: networkId,
            apiKey: $apiKey,
            data: userKey,
        };        
        
        let response: any;

        try {
            response = await fetch(process.env.API_ENDPOINT, {
                method: 'POST',
                body: JSON.stringify(args),
                mode: 'cors',
                headers: {
                'Content-Type': 'application/json',
                },
            });
        } catch (error) {
            console.log("Error!", error);
        }

        let json =  await response.json();
        
        let advertiserId: any;
        let retargetingSegments = json.retargetingSegments;
        let keys = Object.keys(retargetingSegments);
        let advertiser: any;
        if (keys && keys.length > 0) {
            keys.forEach(id => {
                let idString = id.match(/[a-z]+|[^a-z]+/gi);
                advertiserId = idString[1];
            });

            advertiser = await makeManagementRequest('advertiser', 'get', {id: parseInt(advertiserId)}, $apiKey);
        }

        return { json, advertiser };
    }
    
    let userDbPromise: Promise<any> = Promise.resolve({ 
        interests: "...", 
        blockedItems: "...", 
        constent: "...", 
        custom: "...", 
        ip: "...", 
        key: "...", 
        optOut: "...",
        partnerUserIds: "...",
        retargetedSegments: "..."
    });

    $: {       
        userDbPromise = read(networkId, userKey);
    }
</script>

<div class="pb-10">
    <div class="w-full lg:max-w-full lg:flex">
      <div class="border-r border-b bordr-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal flex-grow">
        <div class="mb-5">
          {#await userDbPromise}...{:then u}
              <div class="text-gray-900 font-bold text-2xl mb-4">User Key: {u.json.key}</div>
                <div class="flex-grow bg-white" transition:slide|local>
                    <nav class="mb-8   text-base transform opactiy-100 text-gray-800 font-bold">
                    <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'details' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
                        <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="details" />
                        Details
                    </label>
                    <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'json' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
                        <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="json" />
                        JSON
                    </label>
                    </nav>
                    {#if selectedDetailsPanel === 'details'}
                        <div class="grid grid-cols-2 gap-4">
                        <div>
                          <div class="font-bold">Custom</div>
                          <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                            {#if u.json.custom && Object.keys(u.json.custom).length > 0}
                                {#each Object.entries(u.json.custom) as [key, val]}
                                    {key}: {val}<br>
                                {/each}
                            {:else}
                                (N/A)
                            {/if}
                          </div>
                        </div>
                        <div>
                          <div class="font-bold">Interests</div>
                          <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                            {#if u.json.interests && Object.keys(u.json.interests).length > 0}
                                {#each Object.values(u.json.interests) as val}
                                    {val}<br>
                                {/each}
                            {:else}
                                (N/A)
                            {/if}
                          </div>
                        </div>
                        <div>
                          <div class="font-bold">Blocked Items</div>
                          <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                            {#if u.json.blockedItems.advertisers.length > 0 || u.json.blockedItems.campaigns.length > 0 || u.json.blockedItems.creatives.length > 0 || u.json.blockedItems.flights.length > 0}
                                {#if u.json.blockedItems.advertisers.length > 0}
                                    Advertisers: {u.json.blockedItems.advertisers}
                                {/if}
                                {#if u.json.blockedItems.campaigns.length > 0}
                                    Campaigns: {u.json.blockedItems.campaigns}
                                {/if}
                                {#if u.json.blockedItems.flights.length > 0}
                                    Flights: {u.json.blockedItems.flights}
                                {/if}
                                {#if u.json.blockedItems.creatives.length > 0}
                                    Creatives: {u.json.blockedItems.creatives}
                                {/if}
                            {:else}
                                (N/A)
                            {/if}
                          </div>
                        </div>
                        <div>
                          <div class="font-bold">Partner IDs</div>
                          <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                            {#if u.json.partnerUserIds && Object.keys(u.json.partnerUserIds).length > 0}
                                {#each Object.entries(u.json.partnerUserIds) as [key, val]}
                                    {key}: {val}<br>
                                {/each}
                            {:else}
                                (N/A)
                            {/if}
                          </div>
                        </div>
                        <div>
                          <div class="font-bold">Retargeting Segments</div>
                          <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                            {#if u.json.retargetingSegments && Object.keys(u.json.retargetingSegments).length > 0}
                                {#each Object.entries(u.json.retargetingSegments) as [key, val]}
                                    Advertiser: {u.advertiser.title}   
                                    (ID: {u.advertiser.id} <a
                                        href={`https://app.kevel.co/#!/${networkId}/advertiser/${u.advertiser.id}/campaigns`}
                                        target="_blank">
                                        <Icon class="-mt-1" icon={faExternalLinkAlt} />
                                    </a>)  
                                    Segment: {val}  ({key}:{val})<br>
                                {/each}
                            {:else}
                                (N/A)
                            {/if}
                          </div>
                        </div>
                        <div>
                          <div class="font-bold">Consent</div>
                          <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                            {#if u.json.consent && Object.keys(u.json.consent).length > 0}
                                {#each Object.entries(u.json.consent) as [key, val]}
                                    {key}: {val}<br>
                                {/each}
                            {:else}
                                (N/A)
                            {/if}
                          </div>
                        </div>
                        <div>
                            <div class="font-bold">Opted Out?</div>
                            <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                              {u.json.optOut}
                            </div>
                        </div>
                        <div>
                            <div class="font-bold">Overridden IP Address</div>
                            <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
                              {#if u.json.ip}
                                {u.json.ip}
                              {:else}
                                (N/A)
                              {/if}
                            </div>
                        </div>
                      </div>
                    {:else if selectedDetailsPanel === 'json'}
                        <div class="flex mb-8">
                            <AceEditor
                                lang="json"
                                theme="monokai"
                                width="100%"
                                height="750px"
                                value={JSON.stringify(u.json, undefined, 2)}
                                options={{fontSize: '18px'}}
                                readonly={true} />
                        </div>
                    {/if}
                </div>
          {/await}
        </div>
      </div>
    </div>
  </div>
  