<script lang="ts">
  import { apiKey as apiKeyStore } from "./stores";
  import { run as makeManagementRequest } from "./services/mgmt";
  import { rateType as RateType, goalType as GoalType, capType as CapType, freqCapType as FreqCapType, distributionType as DistributionType } from './Types';
  import pluralize from './utils/pluralize';

  export let value: any;

  let apiKey: string;
  let freqCapType = FreqCapType;
  let capType = CapType;
  let goalTypes = GoalType;
  let rateType = RateType;
  let distributionType = DistributionType;
  let priorityId: any;
  let dailyGoal: any;

  let adFlightCampaignAdvertiserPromise: Promise<any> = Promise.resolve({
      ad: "...",
      flight: "...",
      campaign: "...",
      advertiser: "..."
  });

  let fetchAdFlightCampaignAdvertiser = async (id) => {
      let weight: any;
      let goal: any;
      let goalType: any;
      let goalAmount: any;

    let ad = await makeManagementRequest("ad", "get", {
        id: value.ad,
    }, apiKey);

    if (ad.startDateIso && ad.endDateIso) {
        let totalDuration = new Date(ad.endDateIso).getTime() - new Date(ad.startDateIso).getTime();
        ad.totalDurationInDays = Math.ceil(totalDuration / (1000 * 3600 * 24));

        let remainingDuration = new Date(ad.endDateIso).getTime() - new Date().getTime();
        ad.remainingDurationInDays = Math.ceil(remainingDuration / (1000 * 3600 * 24));
    }

    let flight = await makeManagementRequest("flight", "get", {
        id: value.flight,
    }, apiKey);

    if (flight.startDateIso && flight.endDateIso) {
        let totalDuration = new Date(flight.endDateIso).getTime() - new Date(flight.startDateIso).getTime();
        flight.totalDurationInDays = Math.ceil(totalDuration / (1000 * 3600 * 24));

        let remainingDuration = new Date(flight.endDateIso).getTime() - new Date().getTime();
        flight.remainingDurationInDays = Math.ceil(remainingDuration / (1000 * 3600 * 24));
    }

    let campaign = await makeManagementRequest("campaign", "get", {
      id: value.campaign,
    }, apiKey);

    let advertiser = await makeManagementRequest("advertiser", "get", {
      id: value.advertiser,
    }, apiKey);

    let priority = await makeManagementRequest("priority", "get", {
        id: value.priority,
    }, apiKey);

    if (priority.selectionAlgorithm) {
        priorityId = priority.selectionAlgorithm;
    }

    if (ad.isGoalOverride) {
        if (ad.goalType) {
            goalType = ad.goalType;
            if (ad.goal) {
                goalAmount = ad.goal;
            } else if (ad.impressions > 0) {
                goalAmount = ad.impressions;
            } else if (ad.percentage > 0) {
                goalAmount = ad.percentage;
            }
        }
    } else if (flight.impressions) {
        if (flight.goalType) {
            goalType = flight.goalType;
            goalAmount = flight.impressions;
        }
    } else {
        if (ad.goalType) {
            goalType = ad.goalType;
            if (ad.goal) {
                goalAmount = ad.goal;
            } else if (ad.impressions > 0) {
                goalAmount = ad.impressions;
            } else if (ad.percentage > 0) {
                goalAmount = ad.percentage;
            }
        }
    }

    if (goalAmount) {
        if (goalType) {
            if (goalType === 8 || goalType === 9) {
                goal = `${goalAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}`;
            } else if (goalType === 2) {
                goal = `${goalAmount.toLocaleString()}%`;
            } else {
                goal = `${pluralize(goalAmount.toLocaleString(), goalTypes[goalType])}`;
            }
        }
    }

    if (value.useFlightWeight) {
        weight = value.flightWeight * 100;
    } else {
        weight = value.weight * 100;
    }

    if (ad.totalDurationInDays && goal) {
        let calculateDailyGoal = goalAmount / ad.totalDurationInDays;
        let roundGoal: any;
        if (goalType === 8) {
            roundGoal = calculateDailyGoal.toFixed(2);
            dailyGoal = `$${roundGoal}`
        } else {
            roundGoal = Math.round(calculateDailyGoal);
            dailyGoal = `${pluralize(roundGoal.toLocaleString(), goalTypes[goalType])}`
        }
    } else if (flight.totalDurationInDays && goal) {
        let calculateDailyGoal = goalAmount / flight.totalDurationInDays;
        let roundGoal: any;
        if (goalType === 8) {
            roundGoal = calculateDailyGoal.toFixed(2);
            dailyGoal = `$${roundGoal}`
        } else {
            roundGoal = Math.round(calculateDailyGoal);
            dailyGoal = `${pluralize(roundGoal.toLocaleString(), goalTypes[goalType])}`
        }
    }

    return { ad, flight, campaign, advertiser, goal, goalType, weight, priorityId, dailyGoal }
  }

  $: {
    apiKey = $apiKeyStore;
    adFlightCampaignAdvertiserPromise = fetchAdFlightCampaignAdvertiser(value);
  }
</script>

<div>
    {#await Promise.all([adFlightCampaignAdvertiserPromise])}...{:then [level]}
    <div class="mb-5 text-m">
        {#if level.ad.startDateIso && level.ad.endDateIso && level.ad.isStartEndDateOverride}
            This ad has {level.ad.remainingDurationInDays} of {level.ad.totalDurationInDays} days remaining.
        {:else if level.flight.startDateIso && level.flight.endDateIso}
            This ad has {level.flight.remainingDurationInDays} of {level.flight.totalDurationInDays} days remaining.
        {/if} 
        {#if level.goal}
            {#if level.goalType === 1 || level.goalType === 3 || level.goalType === 7 || level.goalType === 8}
                {#if !level.priorityId || level.priorityId === 0}
                    {#if level.dailyGoal}
                        The goal is {level.dailyGoal} per day.
                    {/if}
                {/if}
            {:else if level.goalType === 9}
                The goal is {level.goal} per day.
            {/if}
        {/if}
        {#if level.weight}
            {#if level.weight <= 100}
                This ad will try to serve in {level.weight}% of all ad requests where its targeting rules are satisfied. 
            {:else if level.weight > 100}
                This ad will try to serve in {level.weight}% of all ad requests where its targeting rules are satisfied. 
                A weight higher than 1 means this ad will be chosen proportionally more often than less competitive ads.
            {/if}
        {/if}
    </div> 
    <div class="grid grid-cols-2 gap-4">
        <div>
            <div class="font-bold">Price</div>
            <div class="overscroll-auto overflow-y-auto text-sm h-20">
                {level.flight.price.toLocaleString('en-US', {style:'currency', currency:'USD'})} {rateType[level.flight.rateType]}
            </div>
        </div>
        <div>
            <div class="font-bold">Goal</div>
            <div class="overscroll-auto overflow-y-auto text-sm h-20">
                {#if level.flight.impressions}
                    {#if level.flight.goalType}
                        {#if level.flight.goalType === 8 || level.flight.goalType === 9}
                            Flight: {level.flight.impressions.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {goalTypes[level.flight.goalType]}<br>
                        {:else if level.flight.goalType === 2}
                            Flight: {level.flight.impressions.toLocaleString()}%<br>
                        {:else}
                            Flight: {pluralize(level.flight.impressions.toLocaleString(), goalTypes[level.flight.goalType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if level.ad.isGoalOverride}
                    {#if level.ad.goal}
                        {#if level.ad.goalType}
                            {#if level.ad.goalType === 8 || level.ad.goalType === 9}
                                Ad: {level.ad.goal.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                                {goalTypes[level.ad.goalType]}<br>
                            {:else if level.ad.goalType === 2}
                                Ad: {level.ad.goal.toLocaleString()}%<br>
                            {:else}
                                Ad: {pluralize(level.ad.goal.toLocaleString(), goalTypes[level.ad.goalType])}<br>
                            {/if}
                            <div>Note: Ad-level goals override flight-level goals.</div><br>
                        {/if}
                    {:else if level.ad.impressions > 0}
                        {#if level.ad.goalType === 8 || level.ad.goalType === 9}
                            Ad: {level.ad.impressions.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {goalTypes[level.ad.goalType]}<br>
                        {:else if level.ad.goalType === 2}
                            Ad: {level.ad.impressions.toLocaleString()}%<br>
                        {:else}
                            Ad: {pluralize(level.ad.impressions.toLocaleString(), goalTypes[level.ad.goalType])}<br>
                        {/if}
                        <div>Note: Ad-level goals override flight-level goals.</div><br>
                    {:else if level.ad.percentage > 0}
                        {#if level.ad.goalType === 8 || level.ad.goalType === 9}
                            Ad: {level.ad.percentage.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {goalTypes[level.ad.goalType]}<br>
                        {:else if level.ad.goalType === 2}
                            Ad: {level.ad.percentage.toLocaleString()}%<br>
                        {:else}
                            Ad: {pluralize(level.ad.percentage.toLocaleString(), goalTypes[level.ad.goalType])}<br>
                        {/if}
                        <div>Note: Ad-level goals override flight-level goals.</div><br>
                    {/if}
                {/if}
                {#if level.ad.distributionType}
                    {#if level.ad.distributionType === 1}
                        Ad Distribution: {distributionType[level.ad.distributionType]}
                    {:else if level.ad.distributionType === 2}
                        Ad Distribution: {level.ad.percentage}%
                    {:else if level.ad.distributionType === 3}
                        Ad Distribution: {level.ad.impressions} Fixed Impressions
                    {/if}
                {/if}
                {#if level.ad.distributionType > 1 && level.ad.goalType && level.ad.isGoalOverride}
                    Warning: Setting an ad goal on non-autobalanced ads is an unsupported configuration and 
                    can result in unexpected behavior. 
                {/if}
            </div>
        </div>
        <div>
            <div class="font-bold">Daily Cap</div>
            <div class="overscroll-auto h-20 overflow-y-auto text-sm">
                {#if level.flight.dailyCapAmount}
                    {#if level.flight.capType}
                        {#if level.flight.capType === 4}
                            Flight: {level.flight.dailyCapAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.flight.capType]}<br>
                        {:else}
                            Flight: {pluralize(level.flight.dailyCapAmount.toLocaleString(), capType[level.flight.capType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if level.campaign.dailyCapAmount}
                    {#if level.campaign.capType}
                        {#if level.campaign.capType === 4}
                            Campaign: {level.campaign.dailyCapAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.campaign.capType]}<br>
                        {:else}
                            Campaign: {pluralize(level.campaign.dailyCapAmount.toLocaleString(), capType[level.campaign.capType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if level.advertiser.dailyCapAmount}
                    {#if level.advertiser.capType}
                        {#if level.advertiser.capType === 4}
                            Advertiser: {level.advertiser.dailyCapAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.advertiser.capType]}<br>
                        {:else}
                            Advertiser: {pluralize(level.advertiser.dailyCapAmount.toLocaleString(), capType[level.advertiser.capType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if !level.flight.dailyCapAmount && !level.campaign.dailyCapAmount && !level.advertiser.dailyCapAmount}
                    (N/A)
                {/if}
            </div>
        </div>
        <div>
            <div class="font-bold">Lifetime Cap </div>
            <div class="overscroll-auto h-20 overflow-y-auto text-sm">
                {#if level.flight.lifetimeCapAmount}
                    {#if level.flight.capType}
                        {#if level.flight.capType === 4}
                            Flight: {level.flight.lifetimeCapAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.flight.capType]}<br>
                        {:else}
                            Flight: {pluralize(level.flight.lifetimeCapAmount.toLocaleString(), capType[level.flight.capType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if level.campaign.lifetimeCapAmount}
                    {#if level.campaign.capType}
                        {#if level.campaign.capType === 4}
                            Campaign: {level.campaign.lifetimeCapAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.campaign.capType]}<br>
                        {:else}
                            Campaign: {pluralize(level.campaign.lifetimeCapAmount.toLocaleString(), capType[level.campaign.capType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if level.advertiser.lifetimeCapAmount}
                    {#if level.advertiser.capType}
                        {#if level.advertiser.capType === 4}
                            Advertiser: {level.advertiser.lifetimeCapAmount.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.advertiser.capType]}<br>
                        {:else}
                            Advertiser: {pluralize(level.advertiser.lifetimeCapAmount.toLocaleString(), capType[level.advertiser.capType])}<br>
                        {/if}
                    {/if}
                {/if}
                {#if !level.flight.lifetimeCapAmount && !level.campaign.lifetimeCapAmount && !level.advertiser.lifetimeCapAmount}
                    (N/A)
                {/if}
            </div>
        </div>
        <div>
            <div class="font-bold">Freq Capping</div>
            <div class="overscroll-auto h-20 overflow-y-auto text-sm">
                {#if level.flight.freqCap}
                    {#if level.flight.capType}
                        {#if level.flight.capType === 4}
                            Flight: {level.flight.freqCap.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.flight.capType]}
                        {:else}
                            Flight: {pluralize(level.flight.freqCap.toLocaleString(), capType[level.flight.capType])}
                        {/if}
                    {/if}
                {/if}
                {#if level.campaign.freqCap}
                    {#if level.campaign.capType}
                        {#if level.campaign.capType === 4}
                            Campaign: {level.advertiser.freqCap.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.campaign.capType]}
                        {:else}
                            Campaign: {pluralize(level.advertiser.freqCap.toLocaleString(), capType[level.campaign.capType])}
                        {/if}
                    {/if}
                {/if}
                {#if level.advertiser.freqCap}
                    {#if level.advertiser.capType}
                        {#if level.advertiser.capType === 4}
                            Advertiser: {level.advertiser.freqCap.toLocaleString('en-US', {style:'currency', currency:'USD'})}
                            {capType[level.advertiser.capType]}
                        {:else}
                            Advertiser: {pluralize(level.advertiser.freqCap.toLocaleString(), capType[level.advertiser.capType])}
                        {/if}
                    {/if}
                {/if}

                {#if level.flight.freqCap && level.flight.capType || level.campaign.freqCap && level.campaign.capType || level.advertiser.freqCap && level.advertiser.capType}
                    per
                    {#if level.ad.freqCapType}
                        {freqCapType[level.ad.freqCapType]}
                    {:else if level.flight.freqCapType}
                        {freqCapType[level.flight.freqCapType]}
                    {:else if level.campaign.freqCapType}
                        {freqCapType[level.campaign.freqCapType]}
                    {:else if level.advertiser.freqCapType}
                        {freqCapType[level.advertiser.freqCapType]}
                    {/if}
                {:else}
                    (N/A)
                {/if}
            </div>
        </div>
        <div>
            {#if level.flight.impressions != 100 && level.ad.goalType != 2 || level.flight.goalType != 2}
            <div class="font-bold">We strongly recommend a 100% Goal while testing to guarantee it will serve on every request.</div>
            {/if}
        </div>
    </div>
  {/await}
</div>
