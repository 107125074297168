export const rateType = {
    1: 'Flat',
    2: 'CPM',
    3: 'CPC',
    4: 'CPA View',
    5: 'CPA Click'
};
export const goalType = {
    1: 'impression',
    2: 'percentage',
    3: 'click',
    7: 'conversion',
    8: 'revenue',
    9: 'daily revenue'
};
export const capType = {
    1: 'impression',
    2: 'click',
    3: 'conversion',
    4: 'revenue'
};
export const freqCapType = {
    1: 'hour',
    2: 'day',
    3: 'minute'
};
export const distributionType = {
    1: 'auto-balanced',
    2: 'percentage',
    3: 'impression'
};
