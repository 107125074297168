import { writable } from 'svelte/store';
let defaultDecisionRequest = {
    placements: [{
            divName: "div0",
            networkId: 23,
            siteId: 667480,
            adTypes: [5],
        }],
    user: {
        key: "abc123",
    },
};
export const inputType = writable('new_decision');
export const decisionRequest = writable(JSON.stringify(defaultDecisionRequest, null, 2));
export const decision = writable(undefined);
export const currentDecision = writable(undefined);
export const isLoading = writable(false);
export const apiKey = writable('');
export const isError = writable(false);
export const errorMessage = writable('');
export const selectedResultPanel = writable('results');
export const selectedDecision = writable(undefined);
export const desiredAdIds = writable(undefined);
decision.subscribe(d => {
    if (!d) {
        return;
    }
    currentDecision.set(d);
});
