const eventList = {
    1: 'View Conversion',
    2: 'Click Conversion',
    3: 'Server to Server Conversion',
    10: 'Upvote',
    11: 'Downvote',
    12: 'Downvote: Uninteresting',
    13: 'Downvote: Misleading',
    14: 'Downvote: Offensive',
    15: 'Downvote: Repetitive',
    16: 'Downvote: Other',
    17: 'Close Ad',
    20: 'Like',
    21: 'Share',
    22: 'Comment',
    30: 'Visible',
    31: 'Hover',
    32: 'Expand Div',
    40: 'Viewable Impression',
    50: 'Share on Facebook',
    51: 'Share on Twitter',
    52: 'Share on Pinterest',
    53: 'Share on reddit',
    54: 'Share on Email',
    60: 'Add to Cart',
    61: 'Remove from Cart',
    62: 'Add to Cart from Details',
    63: 'Add to Wishlist',
    64: 'Add to Registry',
    65: 'Expand Product',
    66: 'Save',
    67: 'Save for Later',
    68: 'Notify',
    69: 'Buy Now',
    70: 'Start',
    71: 'First Quartile',
    72: 'Mid Point',
    73: 'Third Quartile',
    74: 'Complete',
    75: 'Mute',
    76: 'Unmute',
    77: 'Pause',
    78: 'Rewind',
    79: 'Resume',
    80: 'Full Screen',
    81: 'Exit Full',
    82: 'Expand',
    83: 'Collapse',
    84: 'Accept Invitation Linear',
    85: 'Close Linear',
    86: 'Skip',
    87: 'Progress',
    101: 'Comment Reply',
    102: 'Comment Upvote',
    103: 'Comment Downvote',
    201: 'Custom1',
    202: 'Custom2',
    203: 'Custom3',
    204: 'Custom4',
    205: 'Custom5',
    206: 'Custom6',
    207: 'Custom7',
    208: 'Custom8',
    209: 'Custom9',
    210: 'Custom10',
    400: '0 Seconds Viewed',
    401: '1 Seconds Viewed',
    402: '2 Seconds Viewed',
    403: '3 Seconds Viewed',
    404: '4 Seconds Viewed',
    405: '5 Seconds Viewed',
    406: '6 Seconds Viewed',
    407: '7 Seconds Viewed',
    408: '8 Seconds Viewed',
    409: '9 Seconds Viewed',
    410: '10 Seconds Viewed',
    415: '15 Seconds Viewed',
    420: '20 Seconds Viewed',
    425: '25 Seconds Viewed',
    430: '30 Seconds Viewed',
};
export default eventList;
