<script lang="ts">
    import { apiKey as apiKeyStore } from "./stores";
    import { run as makeManagementRequest } from './services/mgmt';

    export let value: any;

    let apiKey: string;

    let adPromise: Promise<any> = Promise.resolve({
        customTargeting: "...",
        activeKeywords: "...",
    });

    let flightPromise: Promise<any> = Promise.resolve({
        siteZoneTargeting: "...",
        geoTargeting: "...",
        customTargeting: "...",
        keywords: "...",
        geoDistanceTargeting: "...",
    });

    let dayPartPromise: Promise<any> = Promise.resolve({ items: "..." });

    let fetchFlightAndRelationships = async (id) => {
      let flight = await makeManagementRequest('flight', 'get', {id: value.flight}, apiKey);

      if (flight.siteZoneTargeting && flight.siteZoneTargeting.length) {
        flight.siteZones = await Promise.all(
          flight.siteZoneTargeting.map(async (szt) => {
            let site = !!szt.siteId ? (await makeManagementRequest('site', 'get', { id: szt.siteId }, apiKey)) : undefined;
            let zone = !!szt.zoneId ? (await makeManagementRequest('zone', 'get', { id: szt.zoneId }, apiKey)) : undefined;

            return { site, zone, isExclude: szt.isExclude };
          })
        );
      };

      if (flight.geoTargeting && flight.geoTargeting.length) {
        flight.geoTargets = await Promise.all(
          flight.geoTargeting.map(async (gt) => {
            let countryAndRegion = !!gt.countryCode ? (await makeManagementRequest('geoTargeting', 'listRegionsForCountry', { countryCode: gt.countryCode }, apiKey)) : undefined;
            let regionName: any;
            flight.geoTargeting.forEach(target => {
              if (target.region) {
                let geoRegions = Object.values(countryAndRegion.regions);
                geoRegions.forEach(regionObj => {
                  if (regionObj.code === target.region) {
                    regionName = regionObj.name;
                  }
                });
              }
            });
            
            return { country: countryAndRegion.name, isExclude: gt.isExclude, region: regionName, countryCode: gt.countryCode, regionCode: gt.region }
          })
        );
      };

      return flight;
    };

    let fetchAdAndRelationships = async (id) => {
      let ad = await makeManagementRequest("ad", "get", { id: value.ad, }, apiKey);
      
      if (ad.siteId) {
        ad.site = await makeManagementRequest("site", "get", { id: ad.siteId }, apiKey);
      };
      if (ad.zoneId) {
        ad.zone = await makeManagementRequest("zone", "get", { id: ad.zoneId }, apiKey);
      };
      return ad;
    };

    $: {
        apiKey = $apiKeyStore;

        adPromise = fetchAdAndRelationships(value.ad);

        flightPromise = fetchFlightAndRelationships(value.flight);

        dayPartPromise = makeManagementRequest("dayPart", "list", {
          flightId: value.flight
        }, apiKey);
    }
</script>

<div class="grid grid-cols-2 gap-4">
    <div>
      <div class="font-bold">Keyword</div>
      <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
        {#await Promise.all([adPromise, flightPromise])}...{:then [ad, flight]}
          {#if ad.activeKeywords.length > 0}
            <div><span class="font-bold">Ad: </span>{ad.activeKeywords}</div>
          {/if}
          {#if flight.keywords}
            <div><span class="font-bold">Flight: </span>{flight.keywords}</div>
          {/if}
        {/await}
      </div>
    </div>
    <div>
      <div class="font-bold">Custom</div>
      <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
        {#await Promise.all([adPromise, flightPromise])}...{:then [ad, flight]}
          {#if ad.customTargeting}
            <div><span class="font-bold">Ad: </span>{ad.customTargeting}</div>
          {/if}
          {#if flight.customTargeting}
            <div><span class="font-bold">Flight: </span>{flight.customTargeting}</div>
          {/if}
        {/await}
      </div>
    </div>
    <div>
      <div class="font-bold">Geo</div>
      <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
        {#await flightPromise}...{:then f}
          {#each f.geoTargets || [] as val}
            {#if val.isExclude}
              {#if val.region}
                <li>Exclude {val.region} ({val.regionCode}), {val.country} ({val.countryCode})</li>
              {:else}
                <li>Exclude {val.country} ({val.countryCode})</li>
              {/if}
            {:else}
              {#if val.region}
                <li>Include {val.region} ({val.regionCode}), {val.country} ({val.countryCode})</li>
              {:else}
                <li>Include {val.country} ({val.countryCode})</li>
              {/if}
            {/if}
          {/each}
        {/await}
      </div>
    </div>
    <div>
      <div class="font-bold">Distance</div>
      <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
        {#await flightPromise}...{:then f}
          {#each Object.entries(f.geoDistanceTargeting) as [key, val]}
            {#if val.streetAddress}
              <li>{val.distance}km from {val.streetAddress}</li>
            {:else}
              <li>{val.distance}km from {val.latitude} {val.longitude}</li>
            {/if}
          {/each}
        {/await}
      </div>
    </div>
    <div>
      <div class="font-bold">Site / Zone</div>
      <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
        {#await flightPromise}...{:then flight}
          {#each flight.siteZones || [] as val}
            <div><span class="font-bold">Flight: </span>
              {#if val.isExcluded}
                Exclude
              {:else}
                Include
              {/if}
              {#if !!val.site?.title}
                Site "{val.site.title} "
              {/if}
              {#if !!val.zone?.name}
                {#if !!val.site?.title}
                  and Zone "{val.zone.name}"
                {:else}
                  Zone "{val.zone.name}"
                {/if}
              {/if}
            </div>
          {/each}
        {/await}
        {#await adPromise}...{:then ad}
          {#if ad.site || ad.zone}
            <div><span class="font-bold">Ad: </span>
              {#if ad.site && ad.site.title}
                Site "{ad.site.title}"
              {/if}
              {#if ad.site && ad.zone}
                and   
              {/if}
              {#if ad.zone && ad.zone.name}
                Zone "{ad.zone.name}"
              {/if}
            </div>
          {/if}
        {/await}
      </div>
    </div>
    <div>
      <div class="font-bold">Day Parting</div>
      <div class="overscroll-auto h-20 rounded-lg overflow-y-auto border-t border-l border-r border-b border-gray-400 p-2 text-sm">
        {#await dayPartPromise}...{:then item}
          {#if item.totalItems > 0}
            {#each Object.entries(item.items) as [key, val]}
              <li>{val.weekDays} | {val.startTime} to {val.endTime}</li>
            {/each}
          {/if}
        {/await}
      </div>
    </div>
  </div>