<script lang="ts">
    import { onDestroy } from 'svelte';
    import { errorMessage } from './stores';

    let errorMessageValue: string;

    let unsubscribe = errorMessage.subscribe(value => {
        errorMessageValue = value;
    });

    onDestroy(unsubscribe);
</script>

<div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8" role="alert">
    <span class="block sm:inline">{errorMessageValue}</span>
</div>
