<script lang="ts">
  import { selectedDecision } from '../stores'

  export let data;
</script>

<div class="flex min-w-full items-center">
  <span class="font-bold block mr-4">Decision:</span>
  <select class="p-2 block w-52 min-w-max mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          bind:value={$selectedDecision}>
    {#each Object.keys(data) as decisionName}
      <option value={decisionName}>{decisionName}</option>
    {/each}
  </select>
</div>