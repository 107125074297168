<script lang='ts'>
  import BucketList from "./candidates/BucketList.svelte";
  import DecisionSelector from "./candidates/DecisionSelector.svelte";
  import { selectedDecision as selectedDecisionStore } from './stores';
  import CandidateCard from "./CandidateCard.svelte";

  export let data: any;

  let selectedDecision: any;
  let selectedBucket: any;
  let candidatesByBucket;
  let selectedByBucket;

  let networkId = data[$selectedDecisionStore].placement.networkId;

  $: {
    selectedDecision = $selectedDecisionStore;
    let b = data[$selectedDecisionStore].buckets[0];
    selectedBucket = [b.priority.id, b.channel.id];

    candidatesByBucket = data[$selectedDecisionStore].results.reduce((agg, r) => {
      let k = `${r.channel}|${r.priority}`;
      if (!agg[k]) { agg[k] = [] };
      agg[k].push(r);
      return agg;
    }, {});

    selectedByBucket = data[$selectedDecisionStore].results.reduce((agg, r) => {
      let k = `${r.channel}|${r.priority}`;
      if (!agg[k]) { agg[k] = [] };
      if (r.info === "selected to serve") {
        agg[k].push(r);
      };
      return agg;
    }, {});
  };
</script>

<div class="min-w-full p-10">
  <DecisionSelector data={data} />
  <div class="flex mt-10 min-w-full">
    <div class="flex flex-col max-w-max mr-8">
      <BucketList
        buckets={data[$selectedDecisionStore].buckets}
        selectedBucket={selectedBucket}
        candidatesByBucket={candidatesByBucket}
        selectedByBucket={selectedByBucket}
        on:click={(e) => selectedBucket = e.detail}/>
    </div>
    <div class="flex flex-col flex-grow">
      {#if candidatesByBucket[`${selectedBucket[1]}|${selectedBucket[0]}`]}
        {#each (candidatesByBucket[`${selectedBucket[1]}|${selectedBucket[0]}`] || []) as value}
            <CandidateCard
              value={value}
              networkId={networkId}
            />
        {/each}
      {:else}
        <h1>No Candidates found for this bucket!</h1>
      {/if}
    </div>
  </div>
</div>