<script lang='ts'>
  import { AceEditor } from 'svelte-ace';
  import { slide } from 'svelte/transition';
  import Icon from 'fa-svelte';
  import "brace/mode/html";
  import "brace/theme/monokai";
  import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
  import { apiKey as apiKeyStore } from './stores';
  import { run as makeManagementRequest } from './services/mgmt';
  import Pixels from './Pixels.svelte';
  import { rateType } from './Types';

  export let key: string;
  export let index: number;
  export let decision: any;
  export let explainerResults: Array<any>;
  export let explainerPlacement: any;

  let apiKey: string;

  let isExpanded: boolean = false;
  let selectedDetailsPanel: string = 'contents';

  let result = explainerResults.find(r => r.ad === decision.adId);
  let clickUrl = decision.clickUrl;
  let impressionUrl = decision.impressionUrl;
  let events = decision.events;
  
  let campaignPromise: Promise<any> = Promise.resolve({name: '...'});
  let creativePromise: Promise<any> = Promise.resolve({title: '...'});
  let flightPromise: Promise<any> = Promise.resolve({name: '...'});
  let advertiserPromise: Promise<any> = Promise.resolve({title: '...'});
  let channelPromise: Promise<any> = Promise.resolve({title: '...'});
  let priorityPromise: Promise<any> = Promise.resolve({name: '...'}); 

  let fetchCreativeAndRelationships = async (id) => {
    let creative = await makeManagementRequest('creative', 'get', { id: decision.creativeId }, apiKey);

    if (creative.adTypeId) {
      let adTypeList = await makeManagementRequest('adType', 'listForChannel', { channelId: result.channel }, apiKey);
      adTypeList.items.forEach(type => {
        if (type.id === creative.adTypeId) {
          creative.adTypeInfo = type;
        }
      });
    }

    if (creative.templateId) {
      creative.template = await makeManagementRequest('creativeTemplate', 'get', { id: creative.templateId }, apiKey);
    }

    return creative;
  }

  $: {
    apiKey = $apiKeyStore;
    campaignPromise = makeManagementRequest('campaign', 'get', { id: decision.campaignId }, apiKey);
    flightPromise = makeManagementRequest('flight', 'get', { id: decision.flightId }, apiKey);
    advertiserPromise = makeManagementRequest('advertiser', 'get', {id: result.advertiser}, apiKey);
    channelPromise = makeManagementRequest('channel', 'get', { id: result.channel }, apiKey);
    priorityPromise = makeManagementRequest('priority', 'get', { id: result.priority }, apiKey);
    creativePromise = fetchCreativeAndRelationships(decision.creativeId);
  };
</script>

<div class='p-10'>
  <div class='w-full lg:max-w-full lg:flex'>
    <div class="h-24 lg:h-auto lg:w-48 flex bg-cover rounded-t lg:rounded-t-none lg:rounded-l items-center justify-center overflow-hidden bg-orange-500">
      <div class="text-3xl font-bold">{key} {#if index}: {index}{/if}</div>
    </div>
    <div class="border-r border-b bordr-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal flex-grow">
      <div class="mb-10">
        <p class="text-sm text-gray-700 flex items-center mb-1">
          Campaign:
          {#await campaignPromise}
            ...
          {:then c}
            {c.name}
          {/await}
          (Id:
          <a href={`https://app.kevel.co/#!/${explainerPlacement.networkId}/campaign/${decision.campaignId}/flights`} target="_blank">
            {decision.campaignId}
            <Icon class="-mt-1" icon={faExternalLinkAlt} />
          </a>
          )
        </p>
        <div class="text-gray-900 font-bold text-2xl mb-4">
          {#await creativePromise}...{:then c}{c.title} {/await}
        </div>

        {#if !!decision.pricing &&  decision.pricing.rateType !== 0}
          <div class="text-gray-700 font-bold text-xl mb-4">${decision.pricing.price} {rateType[decision.pricing.rateType]}</div>
        {/if}

        <div class="mb-4 text-l text-gray-700">
          <span class="font-bold">AdType: </span> 
          {#await creativePromise}...{:then c}
            {#if c.adTypeInfo.name}
              {c.adTypeInfo.name}
            {:else}
              {c.adTypeInfo.height}x{c.adTypeInfo.width}
            {/if}
            <span class="text-gray-600 text-sm whitespace-nowrap">(ID: {c.adTypeId})</span>
          {/await}
        </div>

        <div class="text-gray-700 text-base flex">
          <div>
            <div class="font-bold">Flight</div>
            <div>{#await flightPromise}...{:then f}{f.name}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">(ID:
              <a href={`https://app.kevel.co/#!/${explainerPlacement.networkId}/flight/${decision.flightId}/edit`} target="_blank">
                {decision.flightId}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-16">
            <div class="font-bold">Advertiser</div>
            <div>{#await advertiserPromise}...{:then a}{a.title}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">(ID:
              <a href={`https://app.kevel.co/#!/${explainerPlacement.networkId}/advertiser/${result.advertiser}/campaigns`} target="_blank">
                {result.advertiser}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-16">
            <div class="font-bold">Channel</div>
            <div>{#await channelPromise}...{:then c}{c.title}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">(ID:
              <a href={`https://app.kevel.co/#!/${explainerPlacement.networkId}/channels/`} target="_blank">
                {result.channel}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
          <div class="ml-16">
            <div class="font-bold">Priority</div>
            <div>{#await priorityPromise}...{:then p}{p.name}{/await}</div>
            <div class="text-gray-600 text-sm whitespace-nowrap">(ID:
              <a href={`https://app.kevel.co/#!/${explainerPlacement.networkId}/channel/${result.channel}/priorities/`} target="_blank">
                {result.priority}
                <Icon class="-mt-1" icon={faExternalLinkAlt} />
              </a>)
            </div>
          </div>
        </div>
      </div>
      <div>
        <button on:click={() => isExpanded = !isExpanded} class="text-gray-600 text-sm">
          {isExpanded ? "[-] Hide Details" : "[+] View Details"}
        </button>
      </div>
      {#if isExpanded}
        <div class="flex-grow bg-white" transition:slide|local>
          <nav class="mb-8   text-base transform opactiy-100 text-gray-800 font-bold">
            <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'contents' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
              <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="contents" />
              Contents
            </label>
            <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'pixels' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
              <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="pixels" />
              Pixels
            </label>
            <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 border-b-4 border-orange-300 hover:border-orange-500 hover:border-opacity-100 {selectedDetailsPanel === 'creative-template' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1">
              <input class="hidden" type="radio" bind:group={selectedDetailsPanel} value="creative-template" />
              Creative Template
            </label>
          </nav>
          {#if selectedDetailsPanel === 'contents'}
            <div class="flex mb-8">
              <div>
                <div class="font-bold">Type</div>
                <div>{decision.contents[0].type}</div>
              </div>
              <div class="ml-16">
                <div class="font-bold">Height</div>
                <div>{decision.contents[0].data.height}</div>
              </div>
              <div class="ml-16">
                <div class="font-bold">Width</div>
                <div>{decision.contents[0].data.width}</div>
              </div>
            </div>
            <div class="font-bold mb-2">Body</div>
              <AceEditor
                on:init={(editor) => {
                  editor.detail.setOption('fontSize', '18px');
                }}
                lang="html"
                theme="monokai"
                width="100%"
                height="250px"
                value={decision.contents[0].body}
                readonly={true} />
            <div class="font-bold mb-2 mt-5">Metadata</div>
              {#await creativePromise}
                <div />
              {:then c}
                <AceEditor
                  on:init={(editor) => {
                    editor.detail.setOption('fontSize', '18px');
                  }}
                  lang="html"
                  theme="monokai"
                  width="100%"
                  height="250px"
                  value={c.metadata || ''}
                  readonly={true} />
              {/await}
          {:else if selectedDetailsPanel === 'pixels'}
            <Pixels clickUrl={clickUrl} 
            impressionUrl={impressionUrl}
            events={events}/>
          {:else if selectedDetailsPanel === 'creative-template'}
          <div>
            {#await creativePromise}...{:then c}
              {#if c.templateId}
                <div class="mb-5">
                  <div class="font-bold">Name:</div>
                  {#if c.template.name}
                    {c.template.name}
                  {:else}
                    (N/A)
                  {/if}
                </div>
                <div class="mb-5">
                  <div class="font-bold">Template Values:</div>
                  {#if c.templateValues}
                    {#each Object.entries(JSON.parse(c.templateValues)) as [key, value]}
                      {key}: {value}<br>
                    {/each}
                  {:else}
                    (N/A)
                  {/if}
                </div>
              {:else}
                <div>
                  N/A
                </div>
              {/if}
            {/await}
          </div>
          {/if}
        </div>
      {/if}
    </div>
  </div>
</div>

