<script lang="ts">
  import CandidateCard from "./CandidateCard.svelte";
import DecisionSelector from "./candidates/DecisionSelector.svelte";
  import { selectedDecision as selectedDecisionStore } from './stores';

  export let data: any;

  let selectedDecision: any;
  let networkId = data[$selectedDecisionStore].placement.networkId;

  $: {
    selectedDecision = $selectedDecisionStore;
    console.dir(data);
  }
</script>

<div class="min-w-full p-10">
  <DecisionSelector data={data} />
  <div class="flex mt-10 min-w-full">
    {#if data[selectedDecision] && data[selectedDecision].desiredAds}
      {#each data[selectedDecision].desiredAds as desiredAd}
        <CandidateCard value={desiredAd} networkId={networkId} />
      {/each}
    {/if}
  </div>
</div>