<script>
  import { AceEditor } from "svelte-ace";
  import "brace/mode/json";
  import "brace/theme/monokai";
  import InputBar from "./InputBar.svelte";
  import { decision as decisionStore, apiKey as apiKeyStore, currentDecision, selectedResultPanel } from './stores';

  let decision = '', apiKey;

  $: {
    decision = $decisionStore || '';
    apiKey = $apiKeyStore;
  }

  let setStore = () => {
    if ((apiKey || '') === '') {
      alert('Your Kevel API Key is required!');
      return;
    }

    try {
      if (JSON.stringify($currentDecision) !== decision) {
        $selectedResultPanel = 'results';
      }

      decisionStore.set(JSON.parse(decision));
    } catch {
      // set as an error if we can't parse
    }
  }
</script>

<div class="min-w-full px-8">
  <InputBar />

  <div class="min-w-full shadow-xl">
    <AceEditor
      on:input={(e) => {
        decision = e.detail;
      }}
      lang="json"
      theme="monokai"
      width="100%"
      height="750px"
      value={decision}
      options={{fontSize: '18px'}}
      />
  </div>
</div>

<button class="bg-orange-300 hover:bg-orange-500 text-white font-bold py-2 px-4 border border-orange-500 rounded mt-10"
        on:click={setStore}>
  Take me to the visualization, please!
</button>