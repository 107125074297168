<script lang="ts">
  import SourceToggle from "./SourceToggle.svelte";
	import { inputType, decision, isLoading, isError } from './stores';
	import LoadingOverlay from "./LoadingOverlay.svelte";
	import RequestDecision from "./RequestDecision.svelte";
	import ExplainerEditor from "./ExplainerEditor.svelte";
	import ExplainerUpload from "./ExplainerUpload.svelte";
	import ExplainerVisualizer from "./ExplainerVisualizer.svelte";
	import ResultNavigation from "./ResultNavigation.svelte";
	import ErrorMessage from './ErrorMessage.svelte';

	let isNewDecision: boolean,
			isPasteExplainer: boolean,
			isLoadExplainer: boolean,
			hasDecision: boolean;

	$: {
		isNewDecision = $inputType === 'new_decision';
		isPasteExplainer = $inputType === 'paste_explainer';
		isLoadExplainer = $inputType === 'load_explainer';
		hasDecision = $decision !== undefined;
	}
</script>

{#if $isLoading}
	<LoadingOverlay />
{/if}
<div class="relative min-h-screen flex items-start justify-center">
	<div class="absolute inset-0 bg-gradient-to-br from-blue-800 via-kevel-blue-900 to-kevel-blue-700 pointer-events-none overflow-hidden">
		<div class="grid grid-cols-6 grid-rows-4 w-full h-full transform -skew-y-12 opacity-60">
			<div class="bg-kevel-blue-700"></div>
			<div class="bg-blue-800"></div>
			<div class="bg-kevel-blue-900 col-span-2"></div>
			<div class="bg-gradient-to-br row-start-3 row-span-2 col-start-6"></div>
			<div class="bg-gradient-to-br row-start-4 col-start-1 col-span-3"></div>
			<div></div>
		</div>
	</div>
	<main class="relative font-extrabold flex flex-col items-center px-20 min-w-full">
		<header class="flex items-center text-white py-5 text-3xl">
			<img class="h-12 mr-10" src="/img/kevel-logo-knockout.svg" alt="Kevel Knockout Logo" />
			Kevel Decision Explainer
		</header>
        <div class="text-white text-center text-md italic pb-10 font-medium w-1/2">
             The Decision Explainer helps you debug why a particular ad is winning or losing on your ad requests.
             <a class="underline" href="https://dev.kevel.com/docs/decision-explainer-ui" target="_blank">Visit the Knowledge Base</a>
             to learn more about how to use this tool.
        </div>
		{#if $isError}
			<ErrorMessage />
		{/if}
		<section class="bg-white min-w-full rounded-lg transform bg-opacity-70 flex flex-col items-center pb-14 shadow-2xl">
			{#if hasDecision}
				<ResultNavigation />
				<ExplainerVisualizer />
			{:else}
				<SourceToggle />
				{#if isNewDecision}
					<RequestDecision />
				{:else if isPasteExplainer}
					<ExplainerEditor />
				{:else if isLoadExplainer}
					<ExplainerUpload />
				{/if}
			{/if}
		</section>
	</main>
</div>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
