<script lang="ts">
  import Icon from 'fa-svelte';
  import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
  import { faPaste } from '@fortawesome/free-solid-svg-icons/faPaste';
  import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';

  import { inputType as inputTypeStore } from "./stores";
  import { onDestroy } from 'svelte';

  let inputType;

  let unsubscribe = inputTypeStore.subscribe(value => {
		inputType = value;
	});

  onDestroy(unsubscribe);
</script>

<nav class="mb-6 text-base transform opacity-100 text-gray-800 font-bold min-w-full">
  <input id="new_decision" class="hidden" type="radio" bind:group={$inputTypeStore} value="new_decision">
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {inputType === 'new_decision' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1" for="new_decision">
    <Icon class="mr-2 text-xl -mt-1" icon={faPlusCircle} />
    <span class="hidden lg:inline"><span class="hidden 2xl:inline">Make a </span>New Decision Request</span>
  </label>
  <input id="paste_explainer" class="hidden" type="radio" bind:group={$inputTypeStore} value="paste_explainer">
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {inputType === 'paste_explainer' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1" for="paste_explainer">
    <Icon class="mr-2 text-xl -mt-1" icon={faPaste} />
    <span class="hidden lg:inline">Paste <span class="hidden 2xl:inline">an existing </span>Explainer Result</span>
  </label>
  <input id="load_explainer" class="hidden" type="radio" bind:group={$inputTypeStore} value="load_explainer">
  <label class="transition duration-500 inline-block cursor-pointer py-4 pr-14 lg:pr-20 2xl:pr-24 pl-4 border-b-4 border-orange-300 hover:border-opacity-100 hover:border-orange-500 {inputType === 'load_explainer' ? 'border-opacity-100' : 'border-opacity-0'} -mr-1" for="load_explainer">
    <Icon class="mr-2 text-xl -mt-1" icon={faFolderOpen} />
    <span class="hidden lg:inline">Load <span class="hidden 2xl:inline">an existing</span> Explainer Result <span class="hidden 2xl:inline">from File</span></span>
  </label>
</nav>