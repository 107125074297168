let cache = {};
let cachableOps = ['get'];
export const run = async (obj, op, data, apiKey) => {
    let stringifiedData = JSON.stringify(data);
    if (cachableOps.includes(op) && !!cache[stringifiedData] && !!cache[stringifiedData][op]) {
        return cache[JSON.stringify(data)][op];
    }
    let args = {
        type: 'management',
        data,
        obj,
        op,
        apiKey,
    };
    let response = await fetch(process.env.API_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify(args),
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    let json = await response.json();
    if (cachableOps.includes(op)) {
        if (!cache[stringifiedData]) {
            cache[stringifiedData] = {};
        }
        cache[stringifiedData][op] = json;
    }
    return json;
};
