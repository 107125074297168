<script lang="ts">
  import { apiKey, desiredAdIds } from './stores';
</script>

<div class="flex flex-row-reverse min-w-full">
  <div class="mb-10 pt-0 w-5/12">
    <input type="password"
           placeholder="Kevel API Key"
           class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
           bind:value={$apiKey} />
  </div>
  <div class="mb-10 pt-0 w-5/12 mr-auto">
    <input type=text
           placeholder="Desired Ad IDs"
           class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
           bind:value={$desiredAdIds}>
  </div>
</div>