<script lang='ts'>
  import Icon from 'fa-svelte';
  import { fade } from 'svelte/transition';
  import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

  export let url: string;

  let flashMessage: boolean;

  let copyUrlToClipboard = () => {
    navigator.clipboard.writeText(url);
    flashMessage = true;
    setTimeout(() => flashMessage = false, 1000);
  };
</script>

<div>
    <button class="ml-2" on:click={copyUrlToClipboard}>Copy to Clipboard <Icon class="text-xl -mt-1" icon={faCopy}></Icon></button>
    {#if flashMessage}
        <div class="px-2 text-orange-300" out:fade>Copied to Clipboard!</div>
    {/if}
</div>