<script lang="ts">
  import Dropzone from "svelte-file-dropzone/Dropzone.svelte";
  import { derived } from 'svelte/store';

  import InputBar from "./InputBar.svelte";
  import { apiKey as apiKeyStore, decision, currentDecision, selectedResultPanel } from './stores';

  let isDisabled = derived(
    apiKeyStore,
    $apiKey => ($apiKey || '') === ''
  );

  let handleDrop = (e) => {
    if (e.detail.acceptedFiles.length !== 1) {
      return;
    }

    let reader = new FileReader();

    reader.onerror = () => {
      console.error(reader.error);
    }

    reader.onload = () => {
      let s = reader.result as string;
      let cs = JSON.stringify($currentDecision);

      decision.set(JSON.parse(s));

      if (s !== cs) {
        $selectedResultPanel = 'results';
      }
    }

    reader.readAsText(e.detail.acceptedFiles[0]);
  };
</script>

<div class="min-w-full px-8">
  <InputBar />

  <div class="min-w-full">
    <!-- Barf, but this at least works... -->
    {#if $isDisabled}
      <Dropzone accept={undefined} inputElement={undefined} disabled={true}>
        <p>API Key is required before selecting file</p>
      </Dropzone>
    {:else}
      <Dropzone accept={undefined} inputElement={undefined} on:drop={handleDrop}
                multiple={false}>
        <p>Click here or Drag & Drop to select your Explainer Output!</p>
      </Dropzone>
    {/if}
  </div>
</div>