<script lang='ts'>
    import UrlToggle from './UrlToggle.svelte';
    import EventIds from './EventIds';

    export let clickUrl: any;
    export let impressionUrl: any;
    export let events: any;
    
    let eventIds = EventIds;
</script>

<div class="overscroll-auto h-60 overflow-y-auto">
    <table class="table-auto border-collapse border-2 border-gray-500">
      <thead>
        <tr class="bg-gray-300 border-2 border-gray-500">
          <th class="border w-1/5 border-gray-500">Pixel Name</th>
          <th class="border w-1/10 border-gray-500">Event ID</th>
          <th class="border w-1/2 border-gray-500">URL</th>
          <th class="border w-1/5 border-gray-500"></th>
        </tr>
      </thead>
      <tbody>
        {#if clickUrl}
          <tr>
            <td class="border border-gray-500 p-1">Click</td>
            <td class="border border-gray-500 p-1">N/A</td>
            <td class="border border-gray-500 p-1"><p class="truncate w-80">{clickUrl}</p></td>
            <td class="border border-gray-500 p-1"><UrlToggle url={clickUrl}/></td>
          </tr>
        {/if}
        {#if impressionUrl}
          <tr>
            <td class="border border-gray-500 p-1">Impression</td>
            <td class="border border-gray-500 p-1">N/A</td>
            <td class="border border-gray-500 p-1"><p class="truncate w-80">{impressionUrl}</p></td>
            <td class="border border-gray-500 p-1"><UrlToggle url={impressionUrl}/></td>
          </tr>
        {/if}
        {#if events.length > 0}
          {#each Object.entries(events) as [key, value]}
            <tr>
              <td class="border border-gray-500 p-1">{eventIds[value.id] || 'Custom Event'}</td>
              <td class="border border-gray-500 p-1">{value.id}</td>
              <td class="border border-gray-500 p-1"><p class="truncate w-80">{value.url}</p></td>
              <td class="border border-gray-500 p-1"><UrlToggle url={value.url}/></td>
            </tr>
          {/each}
        {/if}
      </tbody>
    </table>
  </div>
  <div>
    <p class="text-gray-600 text-sm mb-5 mt-5">NOTE: Navigating to the Click or Impression URLs will result in an attribution.</p>
  </div>

  <style>
    tr:nth-child(even) {
        background-color: #e5e7eb;
    }
  </style>