<script lang='ts'>
  import { createEventDispatcher } from 'svelte';
  import { apiKey as apiKeyStore } from '../stores';
  import { run as makeManagementRequest } from '../services/mgmt';

  export let bucket: any;
  export let isSelected: boolean;
  export let candidateCount: number;
  export let selectedCount: number;

  let apiKey: string;

  const dispatch = createEventDispatcher();

  let onClickHandler = () => {
    dispatch('click', [bucket.priority.id, bucket.channel.id]);
  };

  let channelPromise: Promise<any> = Promise.resolve({title: '...'});
  let priorityPromise: Promise<any> = Promise.resolve({name: '...'});

  $: {
    apiKey = $apiKeyStore;
    channelPromise = makeManagementRequest('channel', 'get', { id: bucket.channel.id }, apiKey);
    priorityPromise = makeManagementRequest('priority', 'get', { id: bucket.priority.id }, apiKey);
  };
</script>

<div on:click={onClickHandler} class="w-full py-4 pl-4 pr-16 border-r-4 {isSelected ? 'border-orange-300' : 'border-gray-700'} hover:border-orange-500 bg-gray-400 bg-opacity-50 font-bold">
  {#await priorityPromise}...{:then p}{p.name}{/await} /
   {#await channelPromise}...{:then c}{c.title}{/await} |
    {bucket.priority.type} | {candidateCount} Candidates | {selectedCount} Selections
    
</div>