<script lang="ts">
  import "brace/mode/json";
  import "brace/theme/monokai";
  import Icon from "fa-svelte";
  import { fade } from 'svelte/transition';
  import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';

  import ResultCard from './ResultCard.svelte';
  import { decision, selectedResultPanel, selectedDecision } from './stores';
  import { AceEditor } from "svelte-ace";
  import Candidates from "./Candidates.svelte";
  import UserDb from "./UserDB.svelte";
  import DesiredAds from "./DesiredAds.svelte";

  let data: string;
  let decisionCount: number;
  let selectionsTotalCount: number;
  let blanksCount = 0;
  let candidatesCount: number;
  let userDbKey: string;
  let decisionValues: any;
  let selectionsCount: any;
  let networkId: any;

  let flashMessage: boolean;

  let copyUserKeyToClipboard = () => {
    navigator.clipboard.writeText(userDbKey);
    flashMessage = true;
    setTimeout(() => flashMessage = false, 1000);
  };

  $: {
    data = $decision.explain;

    let explainKeys = Object.keys($decision.explain);
    let explainValues = Object.values($decision.explain);
    let decisionKeys = Object.keys($decision.decisions);
    decisionValues = Object.values($decision.decisions);
    networkId = explainValues[0].placement.networkId;

    userDbKey = $decision.user.key;

    $selectedDecision = explainKeys[0];

    decisionCount = explainKeys.length;

    selectionsCount = Object.values($decision.decisions).reduce((agg: number, arr: Array<any>) => agg + (arr || []).length, 0) as number;
    candidatesCount = Object.values($decision.explain).reduce((agg: number, e: any) => agg + e.results.length, 0) as number;
    decisionValues.forEach(dec => {
      if (!dec || !dec[0]) {
        blanksCount++;
      };
    });
    decisionCount = explainKeys.length - blanksCount;
    selectionsTotalCount = selectionsCount - blanksCount;
  };
</script>

<div class="min-w-full px-8 font-normal">
  <div class="min-w-full shadow-xl">
    <div class="min-w-full flex bg-gray-700 p-4 text-white rounded-lg">
      <div class="pr-10"><span class="font-bold">Decisions:</span> {decisionCount}</div>
      <div class="pr-10"><span class="font-bold">Selections:</span> {selectionsTotalCount}</div>
      <div class="pr-10"><span class="font-bold">Blanks:</span> {blanksCount}</div>
      <div class="pr-10"><span class="font-bold">Candidates:</span> {candidatesCount}</div>
      <div class="flex-grow text-right">
        {#if flashMessage}
          <div class="inline-block px-2 font-bold text-orange-300" out:fade>Value Copied to Clipboard!</div>
        {/if}
        <span class="font-bold">UserDB Key:</span> {userDbKey}
        <button class="ml-2" on:click={copyUserKeyToClipboard}><Icon class="text-xl -mt-1" icon={faCopy}></Icon></button>
      </div>
    </div>

    {#if $selectedResultPanel === 'results'}
      {#each Object.entries($decision.decisions) as [placementName, decisions]}
        {#if decisions !== null}
          {#each decisions as decision, index}
            {#if decision != null}
              <ResultCard key={placementName}
                index={decisions.length === 1 ? undefined : (index + 1)}
                decision={decision}
                explainerResults={data[placementName].results}
                explainerPlacement={data[placementName].placement} />
            {:else}
              <div class="p-10">
                <div class='w-full '>
                  <div class="h-24 lg:h-auto flex bg-cover rounded-t lg:rounded-t-none lg:rounded-l items-center justify-center overflow-hidden bg-orange-500">
                    <div class="text-3xl font-bold ml-10 mr-10">{data[placementName].placement.divName}</div>
                    <div class="border-r border-b bordr-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal flex-grow">
                      <div class="text-gray-700 font-bold text-xl">
                        No decision was selected for this placement request!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/if}
          {/each}
        {:else}
          <div class="p-10">
            <div class='w-full '>
              <div class="h-24 lg:h-auto flex bg-cover rounded-t lg:rounded-t-none lg:rounded-l items-center justify-center overflow-hidden bg-orange-500">
                <div class="text-3xl font-bold ml-10 mr-10">{data[placementName].placement.divName}</div>
                <div class="border-r border-b bordr-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal flex-grow">
                  <div class="text-gray-700 font-bold text-xl">
                    No decision was selected for this placement request!
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/each}
    {:else if $selectedResultPanel === 'candidates'}
      <Candidates data={data} />
    {:else if $selectedResultPanel === 'desiredAds'}
      <DesiredAds data={data} />
    {:else if $selectedResultPanel === 'user'}
      <UserDb userKey={userDbKey} networkId={networkId}/>
    {:else if $selectedResultPanel === 'raw'}
      <div class="min-w-full shadow-xl mt-10">
        <AceEditor
          lang="json"
          theme="monokai"
          width="100%"
          height="750px"
          value={JSON.stringify($decision, undefined, 2)}
          options={{fontSize: '18px'}}
          readonly={true}
          />
      </div>
    {/if}
  </div>
</div>
