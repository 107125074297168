<script lang="ts">
  import { createEventDispatcher } from 'svelte';  
  import Bucket from "./Bucket.svelte";

  export let buckets: Array<any>;
  export let selectedBucket: any;
  export let candidatesByBucket: any;
  export let selectedByBucket: any;

  const dispatch = createEventDispatcher();

  let onClickHandler = (e: CustomEvent) => {
    dispatch('click', e.detail);
  };
</script>

{#each buckets as bucket}
  <Bucket bucket={bucket} 
          on:click={onClickHandler} 
          isSelected={selectedBucket[0] === bucket.priority.id && selectedBucket[1] === bucket.channel.id}
          candidateCount={(candidatesByBucket[`${bucket.channel.id}|${bucket.priority.id}`] || []).length} 
          selectedCount={(selectedByBucket[`${bucket.channel.id}|${bucket.priority.id}`] || []).length} />
{/each}